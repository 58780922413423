const initialState = {
  listDataHistorico: {},
  listDataCalendario: {},
  dataHistorico: {},
  dataCalendario: {},
  accumulatedDebt: "",
  summaryCalendar: {},
  quotasCalendar: {},
  dataPrintValue: {},
};

export const MY_PAYMENT_HISTORICAL_INDEX = "MY_PAYMENT_HISTORICAL_INDEX";
export const MY_PAYMENT_HISTORICAL_INDEXONE = "MY_PAYMENT_HISTORICAL_INDEXONE";
export const MY_PAYMENT_CALENDAR_INDEX = "MY_PAYMENT_CALENDAR_INDEX";
export const MY_PAYMENT_CALENDAR_INDEXONE = "MY_PAYMENT_CALENDAR_INDEXONE";
export const MY_PAYMENT_DEBT = "MY_PAYMENT_DEBT";
export const MY_PAYMENT_CALENDAR_SUMMARY = "MY_PAYMENT_CALENDAR_SUMMARY";
export const MY_PAYMENT_CALENDAR_QUOTES = "MY_PAYMENT_CALENDAR_QUOTES";
export const MY_PAYMENT_HISTORICAL_PRINT = "MY_PAYMENT_HISTORICAL_PRINT";
export const MY_PAYMENT_DEBT_CLEAN = "MY_PAYMENT_DEBT_CLEAN";

export const myPayments = (state = initialState, action) => {
  switch (action.type) {
    case MY_PAYMENT_HISTORICAL_INDEX:
      return {
        ...state,
        listDataHistorico: action.payload,
      };
    case MY_PAYMENT_HISTORICAL_INDEXONE:
      return {
        ...state,
        dataHistorico: action.payload,
      };
    case MY_PAYMENT_CALENDAR_INDEX:
      return {
        ...state,
        listDataCalendario: action.payload,
      };
    case MY_PAYMENT_CALENDAR_INDEXONE:
      return {
        ...state,
        dataCalendario: action.payload,
      };
    case MY_PAYMENT_DEBT:
      return {
        ...state,
        accumulatedDebt: action.payload,
      };
    case MY_PAYMENT_CALENDAR_SUMMARY:
      return {
        ...state,
        summaryCalendar: action.payload,
      };
    case MY_PAYMENT_CALENDAR_QUOTES:
      return {
        ...state,
        quotasCalendar: action.payload,
      };
    case MY_PAYMENT_HISTORICAL_PRINT:
      return {
        ...state,
        dataPrintValue: action.payload,
      };
    case MY_PAYMENT_DEBT_CLEAN:
      return {
        ...state,
        listDataHistorico: {},
        listDataCalendario: {},
        dataHistorico: {},
        dataCalendario: {},
        accumulatedDebt: "",
        summaryCalendar: {},
        quotasCalendar: {},
        dataPrintValue: {},
      };
    default:
      return state;
  }
};

export default myPayments;
