const initialState = {
  listData: {},
  data: {},
};

export const PAGOS_INDEX = "PAGOS_INDEX";
export const PAGOS_INDEXONE = "PAGOS_INDEXONE";
export const PAGOS_CLEAN = "PAGOS_CLEAN";

export const formaPago = (state = initialState, action) => {
  switch (action.type) {
    case PAGOS_INDEX:
      return {
        ...state,
        listData: action.payload,
      };
    case PAGOS_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case PAGOS_CLEAN:
      return {
        ...state,
        listData: {},
        data: {},
      };
    default:
      return state;
  }
};

export default formaPago;
