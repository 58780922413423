export const TypeDocuments = [
  { id: 1, name: "DNI" },
  { id: 2, name: "Pasaporte" },
  { id: 3, name: "Carnet de extranjería" },
];
export const StatusGeneral = [
  { id: 1, key: "active", name: "Activo", status: true },
  { id: 2, key: "inactive", name: "Inactivo", status: false },
];

export const StatusProcess = [
  { id: 1, key: "Aprobado", name: "Aprobado" },
  { id: 2, key: "No aprobado", name: "No aprobado" },
];
// export const TypeRoles = [
//   { id: 1, name: "Super Aministrador", key: "admin", mainUrl: "/" },
//   {
//     id: 5,
//     name: "Supervisor de Mantenimiento",
//     key: "maintenance-supervisor",
//     mainUrl: "/maintenance-order",
//   },
//   {
//     id: 6,
//     name: "Supervisor de Cuadrilla",
//     key: "crew-supervisor",
//     mainUrl: "/maintenance-order",
//   },
//   {
//     id: 4,
//     name: "Supervisor General",
//     key: "general-supervisor",
//     mainUrl: "/maintenance-order",
//   },
// ];

export const TypeRoles = [
  { id: 1, name: "Super Administrador", key: "super-admin", mainUrl: "/" },
  {
    id: 2,
    name: "Administrador Escolástica",
    key: "admin-escolastica",
    mainUrl: "/",
  },
  {
    id: 3,
    name: "Encargado Salón",
    key: "admin-classroom",
    mainUrl: "/",
  },
  {
    id: 4,
    name: "Asistente de grupo",
    key: "assistant-group",
    mainUrl: "/",
  },
  {
    id: 5,
    name: "Administrador Economía",
    key: "admin-economic",
    mainUrl: "/",
  },
  {
    id: 6,
    name: "Cajero",
    key: "cashier",
    mainUrl: "/",
  },
  {
    id: 7,
    name: "Miembro",
    key: "member",
    mainUrl: "/",
  },
];

export const TypeRequest = [
  { id: 1, name: "Reducción temporal de cuota" },
  { id: 2, name: "Recategorización de cuota" },
  { id: 3, name: "Licencia sin pago de cuotas" },
  { id: 4, name: "Licencia con disminución de cuota" },
  { id: 5, name: "Anulación de deuda" },
  { id: 6, name: "Anulación de membresía" },
  { id: 7, name: "Traslado nacional" },
  { id: 8, name: "Traslado internacional" },
];

export const StatusActivities = [
  { id: 1, name: "No Iniciada" },
  { id: 2, name: "En Proceso" },
  { id: 3, name: "Suspendida" },
  { id: 4, name: "Cancelada" },
  { id: 5, name: "Cerrada" },
];

export const StatusOrders = [
  { id: 1, name: "Liberada" },
  { id: 2, name: "Proceso" },
  { id: 3, name: "Por CT" },
  { id: 4, name: "Por CT - Observada" },
  { id: 5, name: "Por CT - Aprobada" },
  { id: 6, name: "Por LQ" },
  { id: 7, name: "Por LQ - Observada" },
  { id: 8, name: "Por LQ - Aprobada" },
];

export const TypesNotice = [
  { id: "D1", name: "Solicitud de Mantenimiento" },
  { id: "D2", name: "Aviso de avería" },
  { id: "D3", name: "Aviso de Actividad" },
];

export const StatusImages = [
  { id: 0, name: "Inicio" },
  { id: 1, name: "En proceso" },
  { id: 2, name: "Terminado" },
  { id: 3, name: "Terminado" },
];

export const StatusRequest = [
  { id: 1, name: "En revisión" },
  { id: 2, name: "Aprobado" },
  { id: 3, name: "No aprobado" },
  { id: 4, name: "Eliminado" },
];

export const StatusHistoryRequest = [
  { id: 2, name: "Aprobado" },
  { id: 3, name: "No aprobado" },
  { id: 4, name: "Eliminado" },
];

export const TypeShapeMap = {
  point: "PUNTO",
  line: "LINEA",
};

export const TypeHeadquarter = [
  { id: 1, name: "Local", value: "local" },
  { id: 2, name: "Nacional", value: "nacional" },
];
export const TypePayment = [
  { id: 1, name: "Pago de cuotas", key: "cuotas" },
  { id: 2, name: "Actividades Culturales", key: "simple" },
  { id: 3, name: "Donación", key: "donacion" },
];

export const TypePaymentsMethods = [
  { id: 1, name: "Efectivo", key: "efectivo" },
  { id: 2, name: "Yape", key: "yape" },
  { id: 3, name: "Otros", key: "otros" },
];

export const TypeValidationStatus = [
  { id: 1, name: "Aprobado", key: "aprobado" },
  { id: 2, name: "No aprobado", key: "no_aprobado" },
];

export const LeveHeadquarter = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
];

export const TypeGenders = [
  { id: 1, name: "Masculino", key: "M" },
  { id: 2, name: "Femenino", key: "F" },
  { id: 3, name: "Otros", key: "otros" },
];

export const DaysWeek = [
  { id: 1, name: "Ninguno", key: "1" },
  { id: 2, name: "Lunes", key: "M" },
  { id: 3, name: "Martes", key: "T" },
  { id: 4, name: "Miércoles", key: "W" },
  { id: 5, name: "Jueves", key: "R" },
  { id: 6, name: "Viernes", key: "F" },
  { id: 7, name: "Sábado", key: "S" },
  { id: 8, name: "Domingo", key: "U" },
];

export const TypeFrequency = [
  { id: 1, name: "Ninguno" },
  { id: 2, name: "Semanal" },
  { id: 3, name: "Quincenal" },
  { id: 4, name: "Mensual" },
  { id: 5, name: "Anual" },
  { id: 6, name: "Variable" },
];

export const TypePass = [
  { id: 1, name: "Pago de cuotas" },
  { id: 2, name: "Actividades Culturales" },
  { id: 3, name: "Donación" },
];

export const TypeCanceledAmount = [
  { id: 1, name: "Si", key: true },
  { id: 2, name: "No", key: false },
];

export const TypeStatusHistorico = [
  { id: 1, name: "Aprobado", key: "APPROVED" },
  { id: 2, name: "Anulado", key: "CANCELLED" },
  { id: 3, name: "Eliminado", key: "DELETED" },
  { id: 4, name: "No Aprobado", key: "DISAPPROVE" },
];

export const TypeStatusCalendar = [
  { id: 1, name: "A tiempo", key: "UP_TO_DATE " },
  { id: 2, name: "Por vencer", key: "FOR_BEATING " },
  { id: 3, name: "Vencido", key: "OVERDUE" },
  { id: 4, name: "Pagado", key: "PAYMENT" },
  { id: 5, name: "Inactivo", key: "INACTIVE" },
];

export const ReasonsNewTransfer = [
  { id: 1, name: "Anulación de nueva membresía", key: 3 },
  { id: 2, name: "Traslado internacional", key: 4 },
  { id: 3, name: "Abandono", key: 5 },
  { id: 4, name: "Falta de conducta", key: 6 },
  { id: 5, name: "Fallecimiento", key: 7 },
];

export const TypeActiveStatus = [
  { id: 1, name: "Activación de miembro", status: true },
  { id: 2, name: "Inactivación de miembro", status: false },
  { id: 3, name: "Baja de miembro", status: false },
];

export const TypeInactiveStatusFirstCase = [
  { id: 1, name: "Activación de miembro", status: false },
  { id: 2, name: "Inactivación de miembro", status: true },
  { id: 3, name: "Baja de miembro", status: false },
];

export const TypeInactiveStatusSecondCase = [
  { id: 1, name: "Activación de miembro", status: false },
  { id: 2, name: "Inactivación de miembro (Traslado Nacional)", status: false },
  { id: 3, name: "Baja de miembro", status: false },
];

export const TypeLowStatus = [
  { id: 1, name: "Activación de miembro", status: false },
  { id: 2, name: "Inactivación de miembro", status: true },
  { id: 3, name: "Baja de miembro", status: true },
];

export const TypeReasonsActiveInactivation = [
  { id: 1, name: "Inasistencias" },
  { id: 2, name: "Traslado Nacional" },
];

export const TypeReasonsInactiveActivation = [{ id: 1, name: "Reactivación" }];

export const TypeReasonsInactiveSpecial = [
  { id: 1, name: "Traslado Nacional" },
];

export const TypeReasonsLow = [{ id: 1, name: "Reincorporación" }];

export const TypeReasonActiveLow = [
  { id: 1, name: "Anulación de membresía" },
  { id: 2, name: "Traslado Internacional" },
  { id: 3, name: "Abandono" },
  { id: 4, name: "Falta de conducta" },
  { id: 5, name: "Fallecimiento" },
];

export const TypeStatusMembesGroup = [
  { id: 1, name: "Activo", key: "ACTIVE" },
  { id: 2, name: "Inactivo", key: "INACTIVE" },
];

export const TypeStatusMember = [
  { id: 1, name: "Activo", key: "ACTIVE" },
  { id: 2, name: "Inactivo", key: "INACTIVE" },
  // { id: 3, name: "Suspendido", key: "SUSPENDED" },
  // { id: 4, name: "Anulado", key: "CANCELLED" },
  // { id: 5, name: "De baja", key: "DOWN" },
];

export const TypeStatus = [
  { id: 1, name: "Activo", key: "ACTIVE" },
  { id: 2, name: "Inactivo", key: "INACTIVE" },
  { id: 3, name: "Suspendido", key: "SUSPENDED" },
  { id: 4, name: "Anulado", key: "CANCELLED" },
  { id: 5, name: "De baja", key: "DOWN" },
];

export const TypeStatusMemberSede = [
  { id: 1, name: "Activo", key: "ACTIVE" },
  { id: 2, name: "Inactivo", key: "INACTIVE" },
  { id: 5, name: "De baja", key: "DOWN" },
];

export const TypeReasonsMember = [
  { id: 1, name: "Traslado Nacional", key: "NATIONAL_TRANSFER" },
  { id: 2, name: "Anulación de membresía", key: "CANCELLED_MEMBER" },
  { id: 3, name: "Traslado Internacional", key: "INTERNATIONAL_TRANSFER" },
  { id: 4, name: "Inasistencias", key: "ABSENCES" },
  { id: 5, name: "Fallecimiento", key: "DEATH" },
  { id: 6, name: "Abandono", key: "ABANDONMENT" },
  { id: 7, name: "Falta de conducta", key: "MISCONDUCT" },
  { id: 8, name: "Reincorporación", key: "REINSTATEMENT" },
  { id: 9, name: "Reactivación", key: "REACTIVATION" },
  { id: 10, name: "Nuevo Miembro", key: "NEW_MEMBER" },
];

export const TypeReasonsDownMember = [
  { id: 1, name: "Anulación de membresía", key: "CANCELLED_MEMBER" },
  { id: 2, name: "Traslado Internacional", key: "INTERNATIONAL_TRANSFER" },
  { id: 3, name: "Fallecimiento", key: "DEATH" },
  { id: 4, name: "Abandono", key: "ABANDONMENT" },
  { id: 5, name: "Falta de conducta", key: "MISCONDUCT" },
];

export const TypeBlood = [
  { id: 99, name: "No sabe" },
  { id: 1, name: "O Rh(+)" },
  { id: 2, name: "A Rh(-)" },
  { id: 3, name: "A Rh(+)" },
  { id: 4, name: "B Rh(-)" },
  { id: 5, name: "B Rh(+)" },
  { id: 6, name: "AB Rh(-)" },
  { id: 7, name: "AB Rh(+)" },
  { id: 8, name: "A1B(+)" },
];

export const TypeRelationship = [
  { id: 1, name: "Padre/Madre" },
  { id: 3, name: "Familiar" },
  { id: 4, name: "Pareja" },
  { id: 5, name: "Esposo (a)" },
  { id: 6, name: "Otro" },
];

export const TypeAwards = [
  { id: 1, name: "Antorcha de cobre" },
  { id: 2, name: "Antorcha de plata" },
  { id: 3, name: "Antorcha de oro" },
  { id: 4, name: "Orden de la hoguera de cobre" },
  { id: 5, name: "Orden de la hoguera de plata" },
  { id: 6, name: "Orden de la hoguera de oro" },
  { id: 7, name: "Hacha sin cadena" },
  { id: 8, name: "Hacha con una cadena" },
  { id: 9, name: "Hacha con dos cadenas" },
  { id: 10, name: "Hacha con tres cadenas" },
  { id: 11, name: "Hacha con cuatro cadenas" },
];

export const ListReportAdm = [
  { id: 1, name: "Cifra de Miembros por filiales", key: 1, order: 1 },
  { id: 2, name: "Lista de Miembros activos", key: 2, order: 2 },
  { id: 3, name: "Lista Total de miembros", key: 3, order: 3 },
  // { id: 4, name: "Altas y Bajas por Sede", key: 4 },
  { id: 5, name: "Lista de Miembros Altas / Bajas", key: 5, order: 4 },
  { id: 6, name: "Histórico de Solicitudes", key: 6, order: 9 },
];

export const ListReportEscolastica = [
  { id: 1, name: "Cantidad de miembros por grupo", key: 7, order: 5 },
  { id: 2, name: "Salones", key: 8, order: 6 },
  { id: 3, name: "Encargados de salón / Carga horaria", key: 9, order: 7 },
];

export const ListReportEconomia = [
  {
    id: 1,
    name: "Histórico de Abonos",
    key: 10,
    order: 8
  },
];

export const TypeCreatedBy = [
  { id: 1, name: "Miembro" },
  { id: 2, name: "Administrativo" },
];

export const TypeCreatedByMyRq = [
  { id: 1, name: "Mi" },
  { id: 2, name: "Administrativo" },
];

export const StatusReports = [
  { id: 1, name: "Activo", key: "ACTIVE" },
  { id: 2, name: "Inactivo", key: "INACTIVE" },
  { id: 3, name: "De baja", key: "DOWN" },
];

export const permissionsAdmin = [
  "/datos-miembro",
  "/mis-abonos",
  "/mis-solicitudes",
  "/mis-actividades",
  "/miembros",
  "/gestion-solicitud",
  "/sede",
  "/cargos",
  "/grado",
  "/abono",
  "/cajas",
  "/cuota",
  "/formas-pago",
  "/salon",
  "/grupo",
  "/materias",
  "/programa",
  "/reporte",
  "/dashboard",
  "/ajustes",
  "/notificaciones",
];

export const permissionsEsco = [
  "/datos-miembro",
  "/mis-abonos",
  "/mis-solicitudes",
  "/mis-actividades",
  "/miembros",
  "/gestion-solicitud",
  "/sede",
  "/cargos",
  "/grado",
  "/formas-pago",
  "/salon",
  "/grupo",
  "/materias",
  "/programa",
  "/reporte",
  "/dashboard",
  "/ajustes",
  "/notificaciones",
];

export const permissionsClas = [
  "/datos-miembro",
  "/mis-abonos",
  "/mis-solicitudes",
  "/mis-actividades",
  "/salon",
  "/reporte",
  "/ajustes",
  "/notificaciones",
];

export const permissionsAssis = [
  "/datos-miembro",
  "/mis-abonos",
  "/mis-solicitudes",
  "/mis-actividades",
  "/miembros",
  "/gestion-solicitud",
  "/salon",
  "/grupo",
  "/reporte",
  "/ajustes",
  "/notificaciones",
];

export const permissionsEco = [
  "/datos-miembro",
  "/mis-abonos",
  "/mis-solicitudes",
  "/mis-actividades",
  "/miembros",
  "/gestion-solicitud",
  "/sede",
  "/cargos",
  "/grado",
  "/abono",
  "/cajas",
  "/cuota",
  "/formas-pago",
  "/materias",
  "/programa",
  "/reporte",
  "/dashboard",
  "/ajustes",
  "/notificaciones",
];

export const permissionsCashier = [
  "/datos-miembro",
  "/mis-abonos",
  "/mis-solicitudes",
  "/mis-actividades",
  "/abono",
  "/reporte",
  "/ajustes",
  "/notificaciones",
];

export const permissionsMember = [
  "/datos-miembro",
  "/ajustes",
  "/notificaciones",
  "/mis-abonos",
  "/mis-solicitudes",
  "/mis-actividades",
];

export default {
  TypeDocuments,
  StatusGeneral,
  StatusProcess,
  TypeRoles,
  TypeRequest,
  StatusOrders,
  StatusActivities,
  TypesNotice,
  StatusImages,
  TypeShapeMap,
  TypePayment,
  TypePaymentsMethods,
  TypeValidationStatus,
  TypeHeadquarter,
  LeveHeadquarter,
  TypeGenders,
  DaysWeek,
  TypeFrequency,
  StatusRequest,
  StatusHistoryRequest,
  TypePass,
  TypeCanceledAmount,
  TypeStatusHistorico,
  TypeStatusCalendar,
  ReasonsNewTransfer,
  TypeActiveStatus,
  TypeInactiveStatusFirstCase,
  TypeInactiveStatusSecondCase,
  TypeLowStatus,
  TypeReasonsActiveInactivation,
  TypeReasonsInactiveActivation,
  TypeReasonsInactiveSpecial,
  TypeReasonsLow,
  TypeReasonActiveLow,
  TypeStatusMember,
  TypeReasonsMember,
  TypeBlood,
  TypeRelationship,
  TypeAwards,
  ListReportAdm,
  ListReportEscolastica,
  ListReportEconomia,
  TypeStatusMembesGroup,
  TypeCreatedBy,
  TypeCreatedByMyRq,
  TypeReasonsDownMember,
  TypeStatus,
  TypeStatusMemberSede,
  StatusReports,
  permissionsAdmin,
  permissionsEsco,
  permissionsClas,
  permissionsAssis,
  permissionsEco,
  permissionsCashier,
  permissionsMember,
};
