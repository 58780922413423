import { combineReducers } from "redux";
import customizer from "./customizer/";
import alert from "./alert/alertReducer";
import auth from "./auth/authReducer";
import user from "./user/userReducer";
import program from "./programas/programasReducer";
import paymentMethods from "./formasPago/formasPagoReducer";
import groups from "./grupos/gruposReducer";
import degree from "./grados/gradosReducer";
import headquarter from "./sedes/sedesReducer";
import resources from "./recursos/resourceReducer";
import boxes from "./cajas/cajasReducer";
import subjects from "./materias/materiasReducer";
import roles from "./cargos/cargosReducer";
import classrooms from "./salones/salonesReducer";
import rateCategory from "./cuotas/rateCetegoryReducer";
import rateHQ from "./cuotas/rateHQReducer";
import myPayments from "./mis_abonos/misAbonosReducer";
import requestManagement from "./solicitud/solicitudReducer";
import myRequest from "./mis_solicitudes/misSolicitudesReducer";
import dashboard from "./dashboard/dashboardReducer";
import payments from "./abonos/abonosReducer";
import members from "./miembros/miembrosReducer";
import reports from "./reports/reportsReducer";
import notifications from "./notificaciones/notificacionesReducer";
import myActivities from "./mis_actividades/misActividadesReducer";

const rootReducer = combineReducers({
  customizer: customizer,
  alert: alert,
  auth: auth,
  user: user,
  groups: groups,
  degree: degree,
  headquarter: headquarter,
  resources: resources,
  program: program,
  paymentMethods: paymentMethods,
  rateCategory: rateCategory,
  rateHQ: rateHQ,
  boxes: boxes,
  subjects: subjects,
  roles: roles,
  classrooms: classrooms,
  myPayments: myPayments,
  myRequest: myRequest,
  payments: payments,
  requestManagement: requestManagement,
  dashboard: dashboard,
  members: members,
  reports: reports,
  notifications: notifications,
  myActivities: myActivities,
});

export default rootReducer;
