const initialState = {
  listDataCategories: {},
  listData: {},
  dataCategorie: {},
  data: {},
  listCategories: {},
  list: {},
};

export const CARGOS_CATEGORIES_INDEX = "CARGOS_CATEGORIES_INDEX";
export const CARGOS_CATEGORIES_INDEXONE = "CARGOS_CATEGORIES_INDEXONE";
export const CARGOS_INDEX = "CARGOS_INDEX";
export const CARGOS_LIST = "CARGOS_LIST";
export const CARGOS_INDEXONE = "CARGOS_INDEXONE";
export const CARGOS_CATEGORIES_LIST = "CARGOS_CATEGORIES_LIST";
export const CARGOS_CLEAN = "CARGOS_CLEAN";

export const cargos = (state = initialState, action) => {
  switch (action.type) {
    case CARGOS_CATEGORIES_INDEX:
      return {
        ...state,
        listDataCategories: action.payload,
      };
    case CARGOS_CATEGORIES_INDEXONE:
      return {
        ...state,
        dataCategorie: action.payload,
      };
    case CARGOS_INDEX:
      return {
        ...state,
        listData: action.payload,
      };
      case CARGOS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case CARGOS_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case CARGOS_CATEGORIES_LIST:
      return {
        ...state,
        listCategories: action.payload,
      };
    case CARGOS_CLEAN:
      return {
        ...state,
        listDataCategories: {},
        listData: {},
        dataCategorie: {},
        data: {},
        listCategories: {},
        list: {},
      };
    default:
      return state;
  }
};

export default cargos;
