const initialState = {
  listData: {},
  data: {},
};

export const USER_INDEX = "USER_INDEX";
export const USER_INDEXONE = "USER_INDEXONE";
export const CLEAN_USER = "CLEAN_USER";

export const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_INDEX:
      return {
        ...state,
        listData: action.payload,
      };
    case USER_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case CLEAN_USER: {
      return {
        ...state, data: {}
      };
    }
    default:
      return state;
  }
};

export default user;
