const initialState = {
  listDataNotifications: {},
  activeNotifications: false,
};

export const NOTIFICATIONS_INDEX = "NOTIFICATIONS_INDEX";
export const NOTIFICATIONS_VERIFY = "NOTIFICATIONS_VERIFY";
export const NOTIFICATIONS_CLEAN = "NOTIFICATIONS_CLEAN";

export const notifications = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_INDEX:
      return {
        ...state,
        listDataNotifications: action.payload,
      };
    case NOTIFICATIONS_VERIFY:
      return {
        ...state,
        activeNotifications: action.payload,
      };
    case NOTIFICATIONS_CLEAN:
      return {
        ...state,
        listDataNotifications: {},
        activeNotifications: false,
      };
    default:
      return state;
  }
};

export default notifications;
