const initialState = {
  listData: [],
  data: {},
};

export const MY_REQUEST_INDEX = "MY_REQUEST_INDEX";
export const MY_REQUEST_INDEXONE = "MY_REQUEST_INDEXONE";
export const MY_REQUEST_CLEAN = "MY_REQUEST_CLEAN";

export const myRequest = (state = initialState, action) => {
  switch (action.type) {
    case MY_REQUEST_INDEX:
      return {
        ...state,
        listData: action.payload,
      };
    case MY_REQUEST_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case MY_REQUEST_CLEAN:
      return {
        ...state,
        listData: [],
        data: {},
      };
    default:
      return state;
  }
};

export default myRequest;
