const initialState = {
    showAlert: false,
    title: "Title",
    message: "Message",
    button: "Confirm",
    back: false,
    init:false,
};

export const alert = (state = initialState, action) => {
    switch (action.type) {
        case "SHOW":
            return {
                ...state,
                showAlert: true,
                title: action.title,
                message: action.message,
                button: action.button,
                back: action.back,
                init:action.init,
            };
        case "HIDDEN":
            return {
                ...state,
                showAlert: false,
            };
        default:
            return state;
    }
}

export default alert;
