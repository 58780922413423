import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "./index.scss";

const ConfirmDialog = ({
  isOpen = false,
  loading = false,
  title = "¿Esta seguro de esta acción?",
  message = null,
  type = null,
  messageDelete = null,
  toggleOpen = () => {},
  onConfirm = () => {},
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleOpen}
      className="modal-theme modal-confirm"
      // backdrop={false}
    >
      <ModalHeader toggle={toggleOpen}>{title}</ModalHeader>

      {message && (
        <ModalBody>
          <p dangerouslySetInnerHTML={{ __html: message }}></p>
        </ModalBody>
      )}

      <ModalFooter>
        <Button
          color="info"
          className="btn-theme-cancel"
          onClick={toggleOpen}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          color={type === "delete" ? "danger" : "primary"}
          onClick={onConfirm}
          disabled={loading}
        >
          {messageDelete
            ? messageDelete
            : type === "delete"
            ? "Eliminar"
            : "Confirmar"}
          {/* {type === "delete" ? "Eliminar" : "Confirmar"} */}
          {loading && <i className="button-loader"></i>}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmDialog;
