const initialState = {
  listDataRate: {},
  dataRate: {},
};

export const RATE_CATEGORY_INDEX = "RATE_CATEGORY_INDEX";
export const RATE_CATEGORY_INDEXONE = "RATE_CATEGORY_INDEXONE";
export const RATE_CATEGORY_CLEAN = "RATE_CATEGORY_CLEAN";

export const rateCategory = (state = initialState, action) => {
  switch (action.type) {
    case RATE_CATEGORY_INDEX:
      return {
        ...state,
        listDataRate: action.payload,
      };
    case RATE_CATEGORY_INDEXONE:
      return {
        ...state,
        dataRate: action.payload,
      };
    case RATE_CATEGORY_CLEAN: {
      return {
        ...state,
        listDataRate: {},
        dataRate: {},
      };
    }
    default:
      return state;
  }
};

export default rateCategory;
