import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ExitToAppOutlined,
  SettingsOutlined,
  NotificationsOutlined,
  NotificationsActive,
} from "@material-ui/icons";
import { history } from "../../../history";
import ConfirmDialog from "../confirm/ConfirmDialog";
import { TypeRoles } from "../../../utility/constants/resources.constants";
import notificationsActions from "../../../redux/actions/notificaciones";
// import { history } from "../../../history";
import "./index.scss";

const ConfigProfile = () => {
  const dispatch = useDispatch();
  const { userRole, memberProfile } = useSelector((state) => state.auth);
  const { activeNotifications } = useSelector((state) => state.notifications);
  const [openModalClose, setOpenModalClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rolPrincipal, setRolPrincipal] = useState("");
  const [imgSideBar, setImgSideBar] = useState(
    "https://icons.veryicon.com/png/o/miscellaneous/two-color-icon-library/user-286.png"
  );

  const toggleOpenConfirm = () => {
    setOpenModalClose(!openModalClose);
  };

  const onConfirm = async () => {
    setLoading(true);
    localStorage.removeItem("token");
    await Promise.all([
      dispatch({ type: "LOGOUT", payload: {} }),
      dispatch({ type: "AUTH_CLEAN" }),
      dispatch({ type: "CAJA_CLEAN" }),
      dispatch({ type: "PAYMENT_CLEAN" }),
      dispatch({ type: "ACTIVITY_CLEAN" }),
      dispatch({ type: "ACTIVITY_CLEAN" }),
      dispatch({ type: "CARGOS_CLEAN" }),
      dispatch({ type: "RATE_CATEGORY_CLEAN" }),
      dispatch({ type: "RATE_HQ_CLEAN" }),
      dispatch({ type: "DASHBOARD_CLEAN" }),
      dispatch({ type: "PAGOS_CLEAN" }),
      dispatch({ type: "DREGREE_CLEAN" }),
      dispatch({ type: "GROUP_CLEAN" }),
      dispatch({ type: "MATERIA_CLEAN" }),
      dispatch({ type: "MEMBERS_CLEAN" }),
      dispatch({ type: "MY_PAYMENT_DEBT_CLEAN" }),
      dispatch({ type: "MA_CLEAN" }),
      dispatch({ type: "MY_REQUEST_CLEAN" }),
      dispatch({ type: "NOTIFICATIONS_CLEAN" }),
      dispatch({ type: "PROGRAM_CLEAN" }),
      dispatch({ type: "MASTER_CLEAN" }),
      dispatch({ type: "REPORT_CLEAN" }),
      dispatch({ type: "SALONES_CLEAN" }),
      dispatch({ type: "HEADQUARTER_CLEAN" }),
      dispatch({ type: "REQUEST_CLEAN" }),
    ]);
    setLoading(false);
    history.push("/login");
  };

  useEffect(() => {
    let rolSpanish =
      userRole != "guest"
        ? TypeRoles.find((x) => x.key == userRole)?.name
        : "Miembro";
    setRolPrincipal(rolSpanish);
  }, [userRole]);

  useEffect(() => {
    setImgSideBar(
      memberProfile?.photo_path
        ? memberProfile?.photo_path
        : "https://icons.veryicon.com/png/o/miscellaneous/two-color-icon-library/user-286.png"
    );
  }, [memberProfile]);

  // history.listen((location, action) => {
  // if (location.pathname) {
  //   // dispatch(notificationsActions.verifyNotificationsAction());
  //   console.log(location.pathname);
  // }
  // });
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(notificationsActions.verifyNotificationsAction());
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="box_main">
      <div className="box_content">
        <div className="d-flex" style={{ marginBottom: "8px" }}>
          <img src={imgSideBar} width={60} height={60} alt="user" />
          <div className="user_name">{memberProfile?.abbreviation}</div>
        </div>
        <div>
          <div className="rol_active">
            <span style={{ fontWeight: "bold" }}>Rol:</span> {rolPrincipal}
          </div>
          <div className="rol_active">
            <span style={{ fontWeight: "bold" }}>Sede: </span>
            {memberProfile?.headquarters
              ? memberProfile?.headquarters[0].headquarter
              : ""}
          </div>
        </div>
      </div>

      <div className="d-flex mb-1">
        <div
          className="box_options"
          onClick={() => history.push("/notificaciones")}
        >
          {activeNotifications ? (
            <NotificationsActive size={15}  style={{ color: '#ED3237' }} />
          ) : (
            <NotificationsOutlined size={15} />
          )}
        </div>
        <div className="box_options" onClick={() => history.push(`/ajustes`)}>
          <SettingsOutlined size={15} />
        </div>
        <div className="box_options">
          <ExitToAppOutlined size={15} onClick={toggleOpenConfirm} />
        </div>
      </div>
      <ConfirmDialog
        isOpen={openModalClose}
        toggleOpen={toggleOpenConfirm}
        loading={loading}
        onConfirm={onConfirm}
        title={"Cerrar sesión"}
        message={"Estás por cerrar sesión. <br/> ¿Deseas continuar?"}
      />
    </div>
  );
};

export default ConfigProfile;
