const initialState = {
  listData: {},
  listDataSede: {},
  data: {},
  dataSede: {},
  listHeadquarter: {},
};

export const CAJA_INDEX = "CAJA_INDEX";
export const CAJA_SEDE_INDEX = "CAJA_SEDE_INDEX";
export const CAJA_INDEXONE = "CAJA_INDEXONE";
export const CAJA_SEDE_INDEXONE = "CAJA_SEDE_INDEXONE";
export const CAJA_HEADQUARTER_LIST = "CAJA_HEADQUARTER_LIST";
export const CAJA_CLEAN = "CAJA_CLEAN";

export const Caja = (state = initialState, action) => {
  switch (action.type) {
    case CAJA_INDEX:
      return {
        ...state,
        listData: action.payload,
      };
    case CAJA_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case CAJA_SEDE_INDEX:
      return {
        ...state,
        listDataSede: action.payload,
      };
    case CAJA_SEDE_INDEXONE:
      return {
        ...state,
        dataSede: action.payload,
      };
    case CAJA_HEADQUARTER_LIST:
      return {
        ...state,
        listHeadquarter: action.payload,
      };
    case CAJA_CLEAN:
      return {
        ...state,
        listData: {},
        listDataSede: {},
        data: {},
        dataSede: {},
        listHeadquarter: {},
      };
    default:
      return state;
  }
};

export default Caja;
