const initialState = {
  listDataHQ: {},
  dataHQ: [],
};

export const RATE_HQ_INDEX = "RATE_HQ_INDEX";
export const RATE_HQ_INDEXONE = "RATE_HQ_INDEXONE";
export const RATE_HQ_INDEXONE_CLEAN = "RATE_HQ_INDEXONE_CLEAN";
export const RATE_HQ_CLEAN = "RATE_HQ_CLEAN";

export const rateHQ = (state = initialState, action) => {
  switch (action.type) {
    case RATE_HQ_INDEX:
      return {
        ...state,
        listDataHQ: action.payload,
      };

    case RATE_HQ_INDEXONE:
      return {
        ...state,
        dataHQ: action.payload,
      };
    case RATE_HQ_INDEXONE_CLEAN:
      return {
        ...state,
        dataHQ: [],
      };
    case RATE_HQ_CLEAN: {
      return {
        ...state,
        listDataHQ: {},
        dataHQ: {},
      };
    }
    default:
      return state;
  }
};

export default rateHQ;
