const initialState = {
  listDataPHistorico: {},
  listMBoxes: {},
  listMovements: {},
  listDataPool: {},
  data: {},
  dataBox: {
    status: "Closed",
  },
  tabActive: 1,
  firstOpenPayment: false,
};

export const PAYMENT_HISTORICAL_INDEX = "PAYMENT_HISTORICAL_INDEX";
export const PAYMENT_CLEAN_HISTORICAL_INDEX = "PAYMENT_CLEAN_HISTORICAL_INDEX";
export const PAYMENT_INDEXONE = "PAYMENT_INDEXONE";
export const PAYMENT_CASHIER_MANAGER_INDEX = "PAYMENT_CASHIER_MANAGER_INDEX";
export const PAYMENT_CLEAN_CASHIER_MANAGER_INDEX =
  "PAYMENT_CLEAN_CASHIER_MANAGER_INDEX";
export const PAYMENT_BOX_INDEXONE = "PAYMENT_BOX_INDEXONE";
export const PAYMENT_MOVEMENTS_INDEX = "PAYMENT_MOVEMENTS_INDEX";
export const PAYMENT_POOL_INDEX = "PAYMENT_POOL_INDEX";
export const PAYMENT_CLEAN_DATABOX = "PAYMENT_CLEAN_DATABOX";
export const PAYMENT_UPDATE_TAB = "PAYMENT_UPDATE_TAB";
export const PAYMENT_DATABOX_OPENED = "PAYMENT_DATABOX_OPENED";
export const PAYMENT_DEFAULT_TAB = "PAYMENT_DEFAULT_TAB";
export const PAYMENT_CHANGE_FIRST_PAYMENT = "PAYMENT_CHANGE_FIRST_PAYMENT";
export const PAYMENT_CLEAN = "PAYMENT_CLEAN";

export const payments = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_HISTORICAL_INDEX:
      return {
        ...state,
        listDataPHistorico: action.payload,
      };
    case PAYMENT_CLEAN_HISTORICAL_INDEX:
      return {
        ...state,
        listDataPHistorico: {},
      };
    case PAYMENT_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case PAYMENT_CASHIER_MANAGER_INDEX:
      return {
        ...state,
        listMBoxes: action.payload,
      };
    case PAYMENT_CLEAN_CASHIER_MANAGER_INDEX:
      return {
        ...state,
        listMBoxes: {},
      };
    case PAYMENT_BOX_INDEXONE:
      return {
        ...state,
        dataBox: action.payload,
      };
    case PAYMENT_MOVEMENTS_INDEX:
      return {
        ...state,
        listMovements: action.payload,
      };
    case PAYMENT_POOL_INDEX:
      return {
        ...state,
        listDataPool: action.payload,
      };
    case PAYMENT_CLEAN_DATABOX:
      return {
        ...state,
        dataBox: action.payload,
      };
    case PAYMENT_UPDATE_TAB:
      return {
        ...state,
        tabActive: action.payload,
      };
    case PAYMENT_DATABOX_OPENED:
      return {
        ...state,
        dataBox: action.payload,
      };
    case PAYMENT_DEFAULT_TAB:
      return {
        ...state,
        tabActive: 1,
      };
    case PAYMENT_CHANGE_FIRST_PAYMENT:
      return {
        ...state,
        firstOpenPayment: action.payload,
      };
    case PAYMENT_CLEAN:
      return {
        ...state,
        listDataPHistorico: {},
        listMBoxes: {},
        listMovements: {},
        listDataPool: {},
        data: {},
        dataBox: {
          status: "Closed",
        },
        tabActive: 1,
        firstOpenPayment: false,
      };
    default:
      return state;
  }
};

export default payments;
