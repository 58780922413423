const initialState = {
  showInitialModal: true,
  currentStep: 1,
  currentStepBoolean: true,
  tabActiveDetail: 1,
  tabActiveAbonos: 1,
  listDataMembersHQ: {},
  listDataTransfers: {},
  listDataLows: {},
  dataMember: {
    awards: [],
    quotas: [],
    statuses: [],
    groups: [],
    headquarters: [],
    degrees: [],
    app_roles: [],
    roles: [],
  },
  data: {},
  commentsMember: {},

  listDataHistoricoMember: {},
  listDataCalendarioMember: {},
  summaryCalendarMember: {},
  quotasCalendarMember: {},
  dataHistoricoMember: {},
  dataCalendarioMember: {},

  dataChangeStatus: {},
  listDataGroups: {},

  dataProcessMember: {},
  dataProcessGroup: {},
};

export const MIEMBROS_HQ_INDEX = "MIEMBROS_HQ_INDEX";
export const TRANSFERS_INDEX = "TRANSFERS_INDEX";
export const LOWS_INDEX = "LOWS_INDEX";
export const MIEMBROS_INDEXONE = "MIEMBROS_INDEXONE";
export const COMMENTS_INDEX = "COMMENTS_INDEX";
export const MEMBERS_MY_PAYMENT_HISTORICAL_INDEX =
  "MEMBERS_MY_PAYMENT_HISTORICAL_INDEX";
export const MEMBERS_MY_PAYMENT_CALENDAR_INDEX =
  "MEMBERS_MY_PAYMENT_CALENDAR_INDEX";
export const MEMBERS_MY_PAYMENT_CALENDAR_SUMMARY =
  "MEMBERS_MY_PAYMENT_CALENDAR_SUMMARY";
export const MEMBERS_MY_PAYMENT_CALENDAR_QUOTES =
  "MEMBERS_MY_PAYMENT_CALENDAR_QUOTES";
export const MEMBERS_MY_PAYMENT_HISTORICAL_INDEXONE =
  "MEMBERS_MY_PAYMENT_HISTORICAL_INDEXONE";
export const MEMBERS_MY_PAYMENT_CALENDAR_INDEXONE =
  "MEMBERS_MY_PAYMENT_CALENDAR_INDEXONE";
export const MEMBERS_CHANGE_STATUS = "MEMBERS_CHANGE_STATUS";
export const MEMBERS_COMMENT_CHANGE_STATUS = "MEMBERS_COMMENT_CHANGE_STATUS";
export const MEMBERS_GROUPS_INDEX = "MEMBERS_GROUPS_INDEX";
export const MEMBERS_DETAIL_UPDATE_TAB = "MEMBERS_DETAIL_UPDATE_TAB";
export const MEMBERS_DETAIL_DEFAULT_TAB = "MEMBERS_DETAIL_DEFAULT_TAB";
export const MEMBERS_DEFAULT_TAB = "MEMBERS_DEFAULT_TAB";
export const MEMBERS_STEPS_DATA = "MEMBERS_STEPS_DATA";
export const MEMBERS_STEPS_DATA_CLEAN = "MEMBERS_STEPS_DATA_CLEAN";
export const MEMBERS_STEPS_GROUPS = "MEMBERS_STEPS_GROUPS";
export const MEMBERS_STEPS_GROUPS_CLEAN = "MEMBERS_STEPS_GROUPS_CLEAN";
export const MEMBERS_CURRENT_SETP_PROCESS = "MEMBERS_CURRENT_SETP_PROCESS";
export const MEMBERS_CURRENT_STEP_PROCESS_CLEAN =
  "MEMBERS_CURRENT_STEP_PROCESS_CLEAN";
export const MEMBERS_SHOW_INITIAL_MODAL = "MEMBERS_SHOW_INITIAL_MODAL";
export const MEMBERS_SHOW_INITIAL_MODAL_CLEAN =
  "MEMBERS_SHOW_INITIAL_MODAL_CLEAN";
export const MEMBERS_CURRENT_SETP_PROCESS_BOOLEAN =
  "MEMBERS_CURRENT_SETP_PROCESS_BOOLEAN";
export const MEMBERS_CURRENT_SETP_PROCESS_BOOLEAN_CLEAN =
  "MEMBERS_CURRENT_SETP_PROCESS_BOOLEAN_CLEAN";
export const MEMBERS_DETAIL_ABONO_UPDATE_TAB =
  "MEMBERS_DETAIL_ABONO_UPDATE_TAB";
export const MEMBERS_DETAIL_ABONO_DEFAULT_TAB =
  "MEMBERS_DETAIL_ABONO_DEFAULT_TAB";
export const MEMBERS_CLEAN = "MEMBERS_CLEAN";

export const Miembros = (state = initialState, action) => {
  switch (action.type) {
    case MIEMBROS_HQ_INDEX:
      return {
        ...state,
        listDataMembersHQ: action.payload,
      };
    case TRANSFERS_INDEX:
      return {
        ...state,
        listDataTransfers: action.payload,
      };
    case LOWS_INDEX:
      return {
        ...state,
        listDataLows: action.payload,
      };
    case MIEMBROS_INDEXONE:
      return {
        ...state,
        dataMember: action.payload,
      };
    case COMMENTS_INDEX:
      return {
        ...state,
        commentsMember: action.payload,
      };
    case MEMBERS_MY_PAYMENT_HISTORICAL_INDEX:
      return {
        ...state,
        listDataHistoricoMember: action.payload,
      };
    case MEMBERS_MY_PAYMENT_CALENDAR_INDEX:
      return {
        ...state,
        listDataCalendarioMember: action.payload,
      };
    case MEMBERS_MY_PAYMENT_CALENDAR_SUMMARY:
      return {
        ...state,
        summaryCalendarMember: action.payload,
      };
    case MEMBERS_MY_PAYMENT_CALENDAR_QUOTES:
      return {
        ...state,
        quotasCalendarMember: action.payload,
      };
    case MEMBERS_MY_PAYMENT_HISTORICAL_INDEXONE:
      return {
        ...state,
        dataHistoricoMember: action.payload,
      };
    case MEMBERS_MY_PAYMENT_CALENDAR_INDEXONE:
      return {
        ...state,
        dataCalendarioMember: action.payload,
      };
    case MEMBERS_CHANGE_STATUS:
      return {
        ...state,
        dataChangeStatus: action.payload,
      };
    case MEMBERS_GROUPS_INDEX:
      return {
        ...state,
        listDataGroups: action.payload,
      };
    case MEMBERS_DETAIL_UPDATE_TAB:
      return {
        ...state,
        tabActiveDetail: action.payload,
      };
    case MEMBERS_DETAIL_DEFAULT_TAB:
      return {
        ...state,
        tabActiveDetail: 1,
      };
    case MEMBERS_DETAIL_ABONO_UPDATE_TAB:
      return {
        ...state,
        tabActiveAbonos: action.payload,
      };
    case MEMBERS_DETAIL_ABONO_DEFAULT_TAB:
      return {
        ...state,
        tabActiveAbonos: 1,
      };
    case MEMBERS_DEFAULT_TAB:
      return {
        ...state,
        tabActiveDetail: 1,
        tabActiveAbonos: 1,
      };
    case MEMBERS_STEPS_DATA:
      return {
        ...state,
        dataProcessMember: action.payload,
      };
    case MEMBERS_STEPS_DATA_CLEAN:
      return {
        ...state,
        dataProcessMember: {},
      };
    case MEMBERS_STEPS_GROUPS:
      return {
        ...state,
        dataProcessGroup: action.payload,
      };
    case MEMBERS_STEPS_GROUPS_CLEAN:
      return {
        ...state,
        dataProcessGroup: {},
      };
    case MEMBERS_CURRENT_SETP_PROCESS:
      return {
        ...state,
        currentStep: action.payload,
      };
    case MEMBERS_CURRENT_STEP_PROCESS_CLEAN:
      return {
        ...state,
        currentStep: 1,
      };
    case MEMBERS_SHOW_INITIAL_MODAL:
      return {
        ...state,
        showInitialModal: action.payload,
      };
    case MEMBERS_SHOW_INITIAL_MODAL_CLEAN:
      return {
        ...state,
        showInitialModal: true,
      };
    case MEMBERS_CURRENT_SETP_PROCESS_BOOLEAN:
      return {
        ...state,
        currentStepBoolean: action.payload,
      };
    case MEMBERS_CURRENT_SETP_PROCESS_BOOLEAN_CLEAN:
      return {
        ...state,
        currentStepBoolean: true,
      };
    case MEMBERS_CLEAN:
      return {
        ...state,
        tabActiveDetail: 1,
        listDataMembersHQ: {},
        listDataTransfers: {},
        listDataLows: {},
        dataMember: {
          awards: [],
          quotas: [],
          statuses: [],
          groups: [],
          headquarters: [],
          degrees: [],
          app_roles: [],
          roles: [],
        },
        data: {},
        commentsMember: {},
        listDataHistoricoMember: {},
        listDataCalendarioMember: {},
        summaryCalendarMember: {},
        quotasCalendarMember: {},
        dataHistoricoMember: {},
        dataCalendarioMember: {},
        dataChangeStatus: {},
        listDataGroups: {},
      };
    default:
      return state;
  }
};

export default Miembros;
