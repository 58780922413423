import React from "react";
import * as Icon from "react-feather";
import * as IconMaterial from "@material-ui/icons";

const navigationConfig = [
  // {
  //   type: "groupHeader",
  //   groupTitle: "GESTIÓN",
  // },

  {
    id: "my-profile",
    title: "Mi Perfil",
    type: "collapse",
    icon: <IconMaterial.PersonOutlined size={20} />,
    permissions: [
      "guest",
      "super-admin",
      "admin-escolastica",
      "admin-classroom",
      "assistant-group",
      "admin-economic",
      "cashier",
      "member",
    ],
    children: [
      {
        id: "home",
        title: "Datos personales",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: [
          "guest",
          "super-admin",
          "admin-escolastica",
          "admin-classroom",
          "assistant-group",
          "admin-economic",
          "cashier",
          "member",
        ],
        navLink: "/datos-miembro",
      },
      {
        id: "abonos",
        title: "Mis abonos",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: [
          "guest",
          "super-admin",
          "admin-escolastica",
          "admin-classroom",
          "assistant-group",
          "admin-economic",
          "cashier",
          "member",
        ],
        navLink: "/mis-abonos",
      },
      {
        id: "my-request",
        title: "Mis solicitudes",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: [
          "guest",
          "super-admin",
          "admin-escolastica",
          "admin-classroom",
          "assistant-group",
          "admin-economic",
          "cashier",
          "member",
        ],
        navLink: "/mis-solicitudes",
      },
      {
        id: "my-activities",
        title: "Mis Actividades",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: [
          "guest",
          "super-admin",
          "admin-escolastica",
          "admin-classroom",
          "assistant-group",
          "admin-economic",
          "cashier",
          "member",
        ],
        navLink: "/mis-actividades",
      },
    ],
  },
  {
    id: "admin",
    title: "Administración",
    type: "collapse",
    icon: <IconMaterial.Settings size={20} />,
    permissions: [
      "super-admin",
      "admin-escolastica",
      "admin-economic",
      "assistant-group",
    ],
    children: [
      {
        id: "miembros",
        title: "Miembros",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: [
          "super-admin",
          "admin-escolastica",
          "assistant-group",
          "admin-economic",
        ],
        navLink: "/miembros",
      },
      {
        id: "gestion-solicitudes",
        title: "Gestión de Solicitudes",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: [
          "super-admin",
          "assistant-group",
          "admin-escolastica",
          "admin-economic",
        ],
        navLink: "/gestion-solicitud",
      },
      {
        id: "sede",
        title: "Sedes",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-escolastica", "admin-economic"],
        navLink: "/sede",
      },
      {
        id: "cargos",
        title: "Cargos",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-escolastica", "admin-economic"],
        navLink: "/cargos",
      },
      {
        id: "grado",
        title: "Grados",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-escolastica", "admin-economic"],
        navLink: "/grado",
      },
    ],
  },
  {
    id: "economy",
    title: "Economía",
    type: "collapse",
    icon: <IconMaterial.MonetizationOn size={20} />,
    permissions: [
      "super-admin",
      "admin-escolastica",
      "admin-economic",
      "cashier",
    ],
    children: [
      {
        id: "payment",
        title: "Abonos",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-economic", "cashier"],
        navLink: "/abono",
      },
      {
        id: "cash",
        title: "Creación de Cajas",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-economic"],
        navLink: "/cajas",
      },
      {
        id: "dues",
        title: "Cuotas",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-economic"],
        navLink: "/cuota",
      },
      {
        id: "grado",
        title: "Formas de pago",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-escolastica", "admin-economic"],
        navLink: "/formas-pago",
      },
    ],
  },
  {
    id: "escolastica",
    title: "Escolástica",
    type: "collapse",
    icon: <IconMaterial.School size={20} />,
    permissions: [
      "super-admin",
      "admin-escolastica",
      "admin-economic",
      "assistant-group",
      "admin-classroom",
    ],
    children: [
      {
        id: "classroom",
        title: "Salones",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: [
          "super-admin",
          "assistant-group",
          "admin-classroom",
          "admin-escolastica",
        ],
        navLink: "/salon",
      },
      {
        id: "group",
        title: "Grupos",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-escolastica", "assistant-group"],
        navLink: "/grupo",
      },
      {
        id: "subjects",
        title: "Materias",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-escolastica", "admin-economic"],
        navLink: "/materias",
      },
      {
        id: "schecule",
        title: "Programas",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-escolastica", "admin-economic"],
        navLink: "/programa",
      },
    ],
  },
  {
    id: "my-report",
    title: "Reportes y Dashboard",
    type: "collapse",
    icon: <IconMaterial.Receipt size={20} />,
    permissions: [
      "super-admin",
      "admin-escolastica",
      "admin-classroom",
      "assistant-group",
      "cashier",
      "admin-economic",
    ],
    children: [
      {
        id: "report",
        title: "Reportes",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: [
          "super-admin",
          "admin-escolastica",
          "admin-classroom",
          "assistant-group",
          "cashier",
          "admin-economic",
        ],
        navLink: "/reporte",
        // extraPaths: ["/reporte/:id"],
      },
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Circle size={8} />,
        permissions: ["super-admin", "admin-escolastica", "admin-economic"],
        navLink: "/dashboard",
      },
    ],
  },
];

export default navigationConfig;
