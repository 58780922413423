import moment from "moment";
import { toast } from "react-toastify";
import resourcesConstants from "../constants/resources.constants";
import L from "leaflet";

export function textQuantity(data, cantidad) {
  let text =
    data.length > cantidad ? data.substring(0, cantidad) + "..." : data;
  return text;
}

export function textPostitive(data) {
  let value = Number(data ? data : "0");
  let text = isNaN(value) || value < 0 ? 0 : value;
  return text;
}

export function textEmpty(value) {
  return !!value && value.length > 0 ? value : "---";
}

export function textNumberEmpty(value) {
  return !!value && String(value).length > 0 ? value : 0;
}

export function formatDate(value) {
  return !!value ? moment(value).format("DD/MM/YYYY") : "---";
}

export function formatHour(value) {
  return !!value ? moment(value, ["HH:mm"]).format("HH:mm") : "---";
}

export function formatErrorField(error) {
  const { status, data } = error?.response;
  let dataInfo = data;

  if (status === 400) {
    let message = data.message;

    if (data.data) {
      dataInfo = data.data;
    }

    const errors = Object.keys(dataInfo ? dataInfo : {});

    if (errors.length > 0) {
      message = dataInfo[`${errors[0]}`].reduce((value, item) => {
        return `${value} ${item}`;
      }, "");
    }

    if (message) toast.error(message);
  }

  return {
    status: status,
    data: dataInfo,
  };
}

export function validateFormatTypeDocument(value, typeDocument) {
  if (typeDocument.current) {
    switch (Number(typeDocument.current)) {
      case 1:
        return value.length === 8 || "Debe tener 8 dígitos";
        break;
      case 3:
        return value.length === 12 || "Debe tener 12 dígitos";
        break;
      default:
        return value.length <= 15 || "Máximo 15 dígitos";
        break;
    }
  }
}

export function validateFormatPassword(value) {
  var mensaje = "La contraseña debe: ";
  if (value.length < 8) {
    mensaje += "tener mínimo 8 dígitos, ";
  }
  if (!value.match(/[a-z]/)) {
    mensaje += "contener al menos una minúscula, ";
  }
  if (!value.match(/[A-Z]/)) {
    mensaje += "contener al menos una mayúscula, ";
  }
  if (!value.match(/[0-9]/)) {
    mensaje += "contener al menos un número, ";
  }
  if (!value.match(/(?=.*[\u0021-\u002b\u003c-\u0040@$!%*?&^#])/)) {
    mensaje += "contener al menos un caracter especial (@$!%*?&^#), ";
  }
  if (mensaje != "La contraseña debe: ") {
    mensaje = mensaje.substr(0, mensaje.length - 2);
    if (value.length != 0) {
      return mensaje;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function validateFinalStatus(status, action) {
  if (Number(status) === Number(resourcesConstants.StatusOrders[7].id)) {
    toast.warning(
      `No puede ${action} debido a que la orden se encuenta en ${resourcesConstants.StatusOrders[7].name}`
    );
    return true;
  } else {
    return false;
  }
}

export function formatColorLine(color) {
  return [
    {
      offset: 0,
      repeat: 20,
      symbol: L.Symbol.dash({
        pixelSize: 10,
        pathOptions: {
          color: color,
          weight: 1,
        },
      }),
    },
  ];
}

export function formatCapital(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function validateNumInteger(value) {
  return (
    Number(String(String(value).split(["."])[0]).length) < 9 ||
    "Máximo 8 enteros"
  );
}

export default {
  textQuantity,
  textPostitive,
  textEmpty,
  textNumberEmpty,
  formatDate,
  formatHour,
  formatErrorField,
  validateFormatPassword,
  validateFormatTypeDocument,
  validateFinalStatus,
  formatColorLine,
  formatCapital,
  validateNumInteger,
};
