const initialState = {
  members: {},
  degrees: [],
  upDown: {},
  upDownMonth: [],
  leaveDue: [],
  downOld: [],
};

export const DASHBOARD_MEMBERS_LIST = "DASHBOARD_MEMBERS_LIST";
export const DASHBOARD_DEGREES_LIST = "DASHBOARD_DEGREES_LIST";
export const DASHBOARD_UP_DOWN_LIST = "DASHBOARD_UP_DOWN_LIST";
export const DASHBOARD_UP_DOWN_MONTH_LIST = "DASHBOARD_UP_DOWN_MONTH_LIST";
export const DASHBOARD_LEAVE_DUE_LIST = "DASHBOARD_LEAVE_DUE_LIST";
export const DASHBOARD_DOWN_OLD_LIST = "DASHBOARD_DOWN_OLD_LIST";
export const DASHBOARD_CLEAN = "DASHBOARD_CLEAN";

export const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_MEMBERS_LIST:
      return {
        ...state,
        members: action.payload,
      };
    case DASHBOARD_DEGREES_LIST:
      return {
        ...state,
        degrees: action.payload,
      };
    case DASHBOARD_UP_DOWN_LIST:
      return {
        ...state,
        upDown: action.payload,
      };
    case DASHBOARD_UP_DOWN_MONTH_LIST:
      return {
        ...state,
        upDownMonth: action.payload,
      };
    case DASHBOARD_LEAVE_DUE_LIST:
      return {
        ...state,
        leaveDue: action.payload,
      };
    case DASHBOARD_DOWN_OLD_LIST:
      return {
        ...state,
        downOld: action.payload,
      };
    case DASHBOARD_CLEAN:
      return {
        ...state,
        members: {},
        degrees: [],
        upDown: {},
        upDownMonth: [],
        leaveDue: [],
        downOld: [],
      };
    default:
      return state;
  }
};

export default Dashboard;
