const API_SUFFIX = "/api";

export const URL_API = {
  apiUrl: process.env.REACT_APP_ADMIN_API_URL_AUTH + API_SUFFIX,
  apiUrlVersion: process.env.REACT_APP_ADMIN_API_URL + API_SUFFIX,
  apiUrlPayment: process.env.REACT_APP_ADMIN_API_URL_PAYMENT + API_SUFFIX,
  apiUrlRequest: process.env.REACT_APP_ADMIN_API_URL_REQUEST + API_SUFFIX,
  apiUrlReport: process.env.REACT_APP_ADMIN_API_URL_REPORT + API_SUFFIX,
  apiUrlNotifications:
    process.env.REACT_APP_ADMIN_API_URL_NOTIFICATIONS + API_SUFFIX,
};

export const URL_BACK = {
  GENERAL: URL_API.apiUrlVersion,
  AUTH: URL_API.apiUrl,
  SUBJECT: URL_API.apiUrlVersion + "/subjects",
  GROUPS: URL_API.apiUrlVersion + "/groups",
  GROUPSBYHQ: URL_API.apiUrlVersion + "/groups/sedes",
  GROUPSBYHQNAL: URL_API.apiUrlVersion + "/groups/nacional",
  DEGREE: URL_API.apiUrlVersion + "/degrees",
  HEADQUARTER: URL_API.apiUrlVersion + "/headquarters",
  PROGRAM: URL_API.apiUrlVersion + "/programs",
  PAYMENT_METHODS: URL_API.apiUrlVersion + "/payment-methods",
  RATE_CATEGORY: URL_API.apiUrlVersion + "/rate-categories",
  RATE: URL_API.apiUrlVersion + "/rate",
  BOX: URL_API.apiUrlVersion + "/boxes",
  BOX_SEDE: URL_API.apiUrlVersion + "/box-sedes",
  ROL: URL_API.apiUrlVersion + "/app-roles",
  ROL_CATEGORIES: URL_API.apiUrlVersion + "/app-role-categories",
  CLASSROOM: URL_API.apiUrlVersion + "/classrooms",
  MEMBERS: URL_API.apiUrlVersion + "/members",
  MY_PAYMENTS: URL_API.apiUrlPayment + "/payments",
  REQUEST_MANAGEMENT: URL_API.apiUrlRequest + "/requests",
  DASHBOARD: URL_API.apiUrlReport + "/dashboard",
  MEMBERS_BY_DOCUMENT: URL_API.apiUrlRequest + "/members",
  PAYMENT_BOX: URL_API.apiUrlPayment + "/boxes",
  REPORTS: URL_API.apiUrlReport + "/reports",
  NOTIFICATIONS: URL_API.apiUrlNotifications + "/notifications",
};
