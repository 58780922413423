const initialState = {
  listData: {},
  data: {},
};

export const DEGREE_INDEX = "DEGREE_INDEX";
export const DEGREE_INDEXONE = "DEGREE_INDEXONE";
export const DREGREE_CLEAN = "DREGREE_CLEAN";

export const degree = (state = initialState, action) => {
  switch (action.type) {
    case DEGREE_INDEX:
      return {
        ...state,
        listData: action.payload,
      };

    case DEGREE_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case DREGREE_CLEAN: {
      return {
        ...state,
        listData: {},
        data: {},
      };
    }
    default:
      return state;
  }
};

export default degree;
