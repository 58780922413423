import { createBrowserHistory } from "history";
import notificationsActions from "./redux/actions/notificaciones";
import { store } from "./redux/storeConfig/store";
export let history = createBrowserHistory();


const verifyNotifications = () => {
  const excludePaths = [
    '/forgot-password', 
    '/login',
    '/recovery-code',
    '/restore-password'
  ];

  if (!excludePaths.includes(history.location.pathname)) {
        store.dispatch(notificationsActions.verifyNotificationsAction());
  }
}

// handle change route
history.listen((location, action) => {
  console.log(location.pathname);
   verifyNotifications();
});

// handle refresh page
if (window.performance) {
    if (performance.navigation.type == 1) {
        verifyNotifications();
    }
}
