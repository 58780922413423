import { instanceAxios } from "../../../utils";
import { URL_BACK, URL_API } from "../../backend.enpoints";
import { headerAuth, headerMultipartAuth } from "../../request";
import { toast } from "react-toastify";
import { formatErrorField } from "../../../utility/formatText";

export function indexAction(params = { page: 1, length: 10 }) {
  return async (dispatch, getState) => {
    try {
      const response = await instanceAxios.get(
        `${URL_BACK.NOTIFICATIONS}?paginate`,
        { params },
        headerAuth()
      );
      dispatch({ type: "NOTIFICATIONS_INDEX", payload: response.data.data });
      return true;
    } catch (error) {
      return formatErrorField(error);
    }
  };
}

export function indexWhitoutPaginationAction() {
  return async (dispatch, getState) => {
    try {
      const params = { pagination: false };
      const response = await instanceAxios.get(
        `${URL_BACK.NOTIFICATIONS}`,
        headerAuth()
      );
      return response.data;
    } catch (error) {
      return formatErrorField(error);
    }
  };
}

export function verifyNotificationsAction() {
  return async (dispatch, getState) => {
    try {
      const response = await instanceAxios.get(
        `${URL_BACK.NOTIFICATIONS}/verify`,
        headerAuth()
      );
      
      if (response.data.data) {
        dispatch({ type: "NOTIFICATIONS_VERIFY", payload: true });
      } else {
        dispatch({ type: "NOTIFICATIONS_VERIFY", payload: false });
      }
      
      return true;
    } catch (error) {
      return formatErrorField(error);
    }
  };
}

export function updateNotificationsAction(data) {
  return async (dispatch, getState) => {
    try {
      const response = await instanceAxios.put(
        `${URL_BACK.NOTIFICATIONS}`,
        data,
        headerAuth()
      );
      return true;
    } catch (error) {
      return formatErrorField(error);
    }
  };
}

export default {
  indexAction,
  verifyNotificationsAction,
  updateNotificationsAction,
  indexWhitoutPaginationAction,
};
