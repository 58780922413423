import React, { useState, useEffect } from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import { ArrowDown } from "react-feather";
import { Menu } from "@material-ui/icons";

import { history } from "../../../history";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";

const UserDropdown = (props) => {
  const dispatch = useDispatch();
  const [openModalClose, setOpenModalClose] = useState(false);

  const onConfirm = (e) => {
    e.preventDefault();
    dispatch({ type: "RESOURCE_UPLOAD", payload: true });
    dispatch({ type: "LOGOUT", payload: {} });
    localStorage.clear();
    localStorage.removeItem("token");
    history.push("/login");
  };

  const toggleModalClose = (e) => {
    setOpenModalClose(!openModalClose);
  };

  return (
    <>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => {
            history.push("/profile");
          }}
        >
          <span className="align-middle">Ver mi perfil</span>
        </DropdownItem>
        <DropdownItem onClick={toggleModalClose}>
          <span className="align-middle">Cerrar sesión</span>
        </DropdownItem>
      </DropdownMenu>

      <Modal
        isOpen={openModalClose}
        toggle={toggleModalClose}
        className="modal-theme modal-alert"
        style={{ maxWidth: 360 }}
      >
        <ModalHeader toggle={toggleModalClose}>
          ¿Quieres cerrar sesión?
        </ModalHeader>

        <ModalBody>Esperamos tenerte de vuelta por aquí</ModalBody>

        <ModalFooter>
          <Button className="btn-theme-cancel" onClick={toggleModalClose}>
            Cancelar
          </Button>
          <Button className="btn-theme-secondary" onClick={onConfirm}>
            Aceptar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
const NavbarUser = (props) => {
  const userName = useSelector((state) => state.auth.userName);

  return (
    <>
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            {/* <div className="user-nav navbar-theme-user"> */}
            {/* {
              userRole !== "admin" &&
              <span className="align-middle">Local: {localName}</span>
            }
            <img src={icProfile} alt="ic-profile" className="pr-0"/> */}
            {/* <span className="user-name text-bold-600 d-sm-flex d-none pl-1">
              {userName}
            </span>
          </div> */}
            <span className="navbar-theme-arrow" data-tour="user">
              <Menu size={15} />
            </span>
          </DropdownToggle>
          <UserDropdown {...props} />
        </UncontrolledDropdown>
      </ul>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.userRole,
  };
};
export default connect(mapStateToProps)(NavbarUser);
