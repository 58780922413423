export function headerAuth() {
  return {
    headers: {
      Authorization: localStorage.getItem("token"),
      Accept: "application/json",
    },
  };
}

export function headerMultipartAuth() {
  return {
    Authorization: localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  };
}

export const Status = {
  NEW: "NEW",
  LOADING: "LOADING",
  READY: "READY",
  SAVING: "SAVING",
  SAVE: "SAVE",
  DELETE: "DELETE",
  ERROR: "ERROR",
  CANCEL: "CANCEL",
};

export default {
  headerAuth,
  headerMultipartAuth,
  Status,
};
