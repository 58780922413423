const initialState = {
  membersHeadquarters: {
    data: [],
  },
  membersCurrent: {
    data: [],
  },
  members: {
    data: [],
  },
  membersGroup: {
    data: [],
  },
  classrooms: {
    data: [],
  },
  membersClassroom: {
    data: [],
  },
  membersApertures: {
    data: [],
  },
  apertures: {
    data: [],
  },
  payments: {
    data: [],
  },
  requests: {
    data: [],
  },
};

export const REPORT_MEMBERS_HEADQUARTERS_LIST =
  "REPORT_MEMBERS_HEADQUARTERS_LIST";
export const REPORT_MEMBERS_CURRENT_LIST = "REPORT_MEMBERS_CURRENT_LIST";
export const REPORT_MEMBERS_LIST = "REPORT_MEMBERS_LIST";
export const REPORT_MEMBERS_GROUP_LIST = "REPORT_MEMBERS_GROUP_LIST";
export const REPORT_CLASSROOMS_LIST = "REPORT_CLASSROOMS_LIST";
export const REPORT_MEMBERS_CLASSROOM_LIST = "REPORT_MEMBERS_CLASSROOM_LIST";
export const REPORT_MEMBERS_APERTURES_LIST = "REPORT_MEMBERS_APERTURES_LIST";
export const REPORT_APERTURES_LIST = "REPORT_APERTURES_LIST";
export const REPORT_PAYMENTS_LIST = "REPORT_PAYMENTS_LIST";
export const REPORT_REQUESTS_LIST = "REPORT_REQUESTS_LIST";
export const REPORT_MEMBERS_APERTURES_CLEAN = "REPORT_MEMBERS_APERTURES_CLEAN";
export const REPORT_CLEAN = "REPORT_CLEAN";

export const report = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_MEMBERS_HEADQUARTERS_LIST:
      return {
        ...state,
        membersHeadquarters: action.payload,
      };
    case REPORT_MEMBERS_CURRENT_LIST:
      return {
        ...state,
        membersCurrent: action.payload,
      };
    case REPORT_MEMBERS_LIST:
      return {
        ...state,
        members: action.payload,
      };
    case REPORT_MEMBERS_GROUP_LIST:
      return {
        ...state,
        membersGroup: action.payload,
      };
    case REPORT_CLASSROOMS_LIST:
      return {
        ...state,
        classrooms: action.payload,
      };
    case REPORT_MEMBERS_CLASSROOM_LIST:
      return {
        ...state,
        membersClassroom: action.payload,
      };
    case REPORT_MEMBERS_APERTURES_LIST:
      return {
        ...state,
        membersApertures: action.payload,
      };
    case REPORT_APERTURES_LIST:
      return {
        ...state,
        apertures: action.payload,
      };
    case REPORT_PAYMENTS_LIST:
      return {
        ...state,
        payments: action.payload,
      };
    case REPORT_REQUESTS_LIST:
      return {
        ...state,
        requests: action.payload,
      };
    case REPORT_MEMBERS_APERTURES_CLEAN:
      return {
        ...state,
        membersApertures: {
          data: [],
        },
      };
    case REPORT_CLEAN:
      return {
        ...state,
        membersHeadquarters: {
          data: [],
        },
        membersCurrent: {
          data: [],
        },
        members: {
          data: [],
        },
        membersGroup: {
          data: [],
        },
        classrooms: {
          data: [],
        },
        membersClassroom: {
          data: [],
        },
        membersApertures: {
          data: [],
        },
        apertures: {
          data: [],
        },
        payments: {
          data: [],
        },
        requests: {
          data: [],
        },
      };
    default:
      return state;
  }
};

export default report;
