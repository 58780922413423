const initialState = {
  tabActiveAdmin: 1,
  tabActiveDetail: 1,
  isNationalClassroom: false,
  nameMemberActive: "",
  dataSalonSede: {},
  listDataSalones: {},
  listDataSalonesSedes: {},
  listDataSalonesNacional: {},
  listDataDetailStudent: {},
  listDataDetailQualification: {},
  listDataDetailAssistance: {},
  listDataMemberQ: {},
  listDataMemberA: {},
  dataAssistance: {},
  listDataAssistance: {},
  newNumbAssistance: "",
  listGroupsModal: {},
  listGroupsNationalModal: {},
  dataQualification: {},
  listDataQualification: {},
};

export const SALONES_IS_NATIONAL = "SALONES_IS_NATIONAL";
export const SALONES_MEMBER_NAME = "SALONES_MEMBER_NAME";
export const SALONES_INDEXONE = "SALONES_INDEXONE";
export const SALONES_INDEX = "SALONES_INDEX";
export const SALONES_SEDES_INDEX = "SALONES_SEDES_INDEX";
export const SALONES_NACIONAL_INDEX = "SALONES_NACIONAL_INDEX";
export const SALONES_DETAIL_STUDENT_INDEX = "SALONES_DETAIL_STUDENT_INDEX";
export const SALONES_DETAIL_QUALIFICATION_INDEX =
  "SALONES_DETAIL_QUALIFICATION_INDEX";
export const SALONES_DETAIL_ASSISTANCE_INDEX =
  "SALONES_DETAIL_ASSISTANCE_INDEX";
export const SALONES_QUALIFICATIONS_MEMBER = "SALONES_QUALIFICATIONS_MEMBER";
export const SALONES_ASSISTS_MEMBER = "SALONES_ASSISTS_MEMBER";
export const SALONES_DETAIL_ASSISTANCE_BY_ID =
  "SALONES_DETAIL_ASSISTANCE_BY_ID";
export const SALONES_INDEX_DETAIL_ASSISTANCE_BY_ID =
  "SALONES_INDEX_DETAIL_ASSISTANCE_BY_ID";
export const SALONES_NEW_NUMB_ASSISTANCE = "SALONES_NEW_NUMB_ASSISTANCE";
export const SALONES_GROUP_PROGRAM_INDEX = "SALONES_GROUP_PROGRAM_INDEX";
export const SALONES_GROUP_NATIONAL_PROGRAM_INDEX =
  "SALONES_GROUP_NATIONAL_PROGRAM_INDEX";
export const SALONES_QUALIFICATIONS_BY_ID_DETAIL =
  "SALONES_QUALIFICATIONS_BY_ID_DETAIL";
export const SALONES_TABLA_QUALIFICATIONS_BY_ID_DETAIL =
  "SALONES_TABLA_QUALIFICATIONS_BY_ID_DETAIL";
export const SALONES_UPDATE_TAB = "SALONES_UPDATE_TAB";
export const SALONES_DETAIL_UPDATE_TAB = "SALONES_DETAIL_UPDATE_TAB";
export const SALONES_DEFAULT_TAB = "SALONES_DEFAULT_TAB";
export const SALONES_DETAIL_DEFAULT_TAB = "SALONES_DETAIL_DEFAULT_TAB";
export const SALONES_CLEAN = "SALONES_CLEAN";
export const SALONES_NATIONAL_CLEAN = "SALONES_NATIONAL_CLEAN";
export const SALONES_SEDES_CLEAN = "SALONES_SEDES_CLEAN";

export const Classroom = (state = initialState, action) => {
  switch (action.type) {
    case SALONES_IS_NATIONAL:
      return {
        ...state,
        isNationalClassroom: action.payload,
      };
    case SALONES_MEMBER_NAME:
      return {
        ...state,
        nameMemberActive: action.payload,
      };
    case SALONES_INDEXONE:
      return {
        ...state,
        dataSalonSede: action.payload,
      };
    case SALONES_INDEX:
      return {
        ...state,
        listDataSalones: action.payload,
      };
    case SALONES_SEDES_INDEX:
      return {
        ...state,
        listDataSalonesSedes: action.payload,
      };
    case SALONES_NACIONAL_INDEX:
      return {
        ...state,
        listDataSalonesNacional: action.payload,
      };
    case SALONES_DETAIL_STUDENT_INDEX:
      return {
        ...state,
        listDataDetailStudent: action.payload,
      };
    case SALONES_DETAIL_QUALIFICATION_INDEX:
      return {
        ...state,
        listDataDetailQualification: action.payload,
      };
    case SALONES_DETAIL_ASSISTANCE_INDEX:
      return {
        ...state,
        listDataDetailAssistance: action.payload,
      };
    case SALONES_QUALIFICATIONS_MEMBER:
      return {
        ...state,
        listDataMemberQ: action.payload,
      };
    case SALONES_ASSISTS_MEMBER:
      return {
        ...state,
        listDataMemberA: action.payload,
      };
    case SALONES_DETAIL_ASSISTANCE_BY_ID:
      return {
        ...state,
        dataAssistance: action.payload,
      };
    case SALONES_INDEX_DETAIL_ASSISTANCE_BY_ID:
      return {
        ...state,
        listDataAssistance: action.payload,
      };
    case SALONES_NEW_NUMB_ASSISTANCE:
      return {
        ...state,
        newNumbAssistance: action.payload,
      };
    case SALONES_GROUP_PROGRAM_INDEX:
      return {
        ...state,
        listGroupsModal: action.payload,
      };
    case SALONES_GROUP_NATIONAL_PROGRAM_INDEX:
      return {
        ...state,
        listGroupsNationalModal: action.payload,
      };
    case SALONES_QUALIFICATIONS_BY_ID_DETAIL:
      return {
        ...state,
        dataQualification: action.payload,
      };
    case SALONES_TABLA_QUALIFICATIONS_BY_ID_DETAIL:
      return {
        ...state,
        listDataQualification: action.payload,
      };
    case SALONES_UPDATE_TAB:
      return {
        ...state,
        tabActiveAdmin: action.payload,
      };
    case SALONES_DETAIL_UPDATE_TAB:
      return {
        ...state,
        tabActiveDetail: action.payload,
      };
    case SALONES_DETAIL_DEFAULT_TAB:
      return {
        ...state,
        tabActiveDetail: 1,
      };
    case SALONES_DEFAULT_TAB:
      return {
        ...state,
        tabActiveAdmin: 1,
        tabActiveDetail: 1,
      };
    case SALONES_NATIONAL_CLEAN:
      return {
        ...state,
        listDataSalonesNacional: {},
      };
    case SALONES_SEDES_CLEAN:
      return {
        ...state,
        listDataSalonesSedes: {},
      };
    case SALONES_CLEAN:
      return {
        tabActiveAdmin: 1,
        tabActiveDetail: 1,
        isNationalClassroom: false,
        nameMemberActive: "",
        dataSalonSede: {},
        listDataSalones: {},
        listDataSalonesSedes: {},
        listDataSalonesNacional: {},
        listDataDetailStudent: {},
        listDataDetailQualification: {},
        listDataDetailAssistance: {},
        listDataMemberQ: {},
        listDataMemberA: {},
        dataAssistance: {},
        listDataAssistance: {},
        newNumbAssistance: "",
        listGroupsModal: {},
        listGroupsNationalModal: {},
        dataQualification: {},
        listDataQualification: {},
      };
    default:
      return state;
  }
};

export default Classroom;
