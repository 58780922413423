const initialState = {
  listDataGrupos: {},
  listDataGroupsByHq: {},
  listDataGruposSede: {},
  dataGroupHq: {},
  membersGrupoSede: {},
  listDataGruposSedeNacional: {},
  dataGrupo: {},
  dataGrupoSede: {},
  listMembersGroups: {},
};

export const GROUPS_INDEX = "GROUPS_INDEX";
export const GROUPS_LIST_BY_HQ_INDEX = "GROUPS_LIST_BY_HQ_INDEX";
export const GROUP_HQ_INDEX = "GROUP_HQ_INDEX";
export const GROUP_DETAIL_HQ_INDEXONE = "GROUP_DETAIL_HQ_INDEXONE";
export const MEMBERS_DETAILS_INDEX = "MEMBERS_DETAILS_INDEX";
export const GROUP_HQ_NATIONAL_INDEX = "GROUP_HQ_NATIONAL_INDEX";
export const GROUPS_INDEXONE = "GROUPS_INDEXONE";
export const MEMBERS_GROUPS_LIST = "MEMBERS_GROUPS_LIST";
export const GROUP_CLEAN = "GROUP_CLEAN";
export const CLEAN_GROUP_HQ = "CLEAN_GROUP_HQ";

export const groups = (state = initialState, action) => {
  switch (action.type) {
    case GROUPS_INDEX:
      return {
        ...state,
        listDataGrupos: action.payload,
      };
    case GROUPS_LIST_BY_HQ_INDEX:
      return {
        ...state,
        listDataGroupsByHq: action.payload,
      };
    case GROUP_HQ_INDEX:
      return {
        ...state,
        listDataGruposSede: action.payload,
      };
    case GROUP_DETAIL_HQ_INDEXONE:
      return {
        ...state,
        dataGroupHq: action.payload,
      };
    case MEMBERS_DETAILS_INDEX:
      return {
        ...state,
        membersGrupoSede: action.payload,
      };
    case CLEAN_GROUP_HQ:
      return {
        ...state,
        listDataGruposSede: {},
      };
    case GROUPS_INDEXONE:
      return {
        ...state,
        dataGrupo: action.payload,
      };
    case GROUP_HQ_NATIONAL_INDEX:
      return {
        ...state,
        listDataGruposSedeNacional: action.payload,
      };
    case MEMBERS_GROUPS_LIST:
      return {
        ...state,
        listMembersGroups: action.payload,
      };
    case GROUP_CLEAN: {
      return {
        ...state,
        listDataGrupos: {},
        listDataGroupsByHq: {},
        listDataGruposSede: {},
        dataGroupHq: {},
        membersGrupoSede: {},
        listDataGruposSedeNacional: {},
        dataGrupo: {},
        dataGrupoSede: {},
        listMembersGroups: {},
      };
    }
    default:
      return state;
  }
};

export default groups;
