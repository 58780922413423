const horizontalMenuConfig = [
  {
    id: "order-to-attend",
    title: "Por atender",
    type: "item",
    permissions: ["kitchener"],
    navLink: "/order-to-attend"
  },
  {
    id: "history-orders",
    title: "Historial",
    type: "item",
    permissions: ["kitchener"],
    navLink: "/history-orders"
  }
]

export default horizontalMenuConfig
