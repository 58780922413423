const initialState = {
  listData: {},
  data: {},
};

export const PROGRAM_INDEX = "PROGRAM_INDEX";
export const PROGRAM_INDEXONE = "PROGRAM_INDEXONE";
export const PROGRAM_CLEAN = "PROGRAM_CLEAN";

export const program = (state = initialState, action) => {
  switch (action.type) {
    case PROGRAM_INDEX:
      return {
        ...state,
        listData: action.payload,
      };

    case PROGRAM_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case PROGRAM_CLEAN: {
      return {
        ...state,
        listData: {},
        data: {},
      };
    }
    default:
      return state;
  }
};

export default program;
