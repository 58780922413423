const initialState = {
  userRole: "",
  userName: "",
  user: {},
  token: "",
  permissions: [],
  headquarter: {},
  memberId: "",
  firstLogin: false,
  roles: [],
  member: {},
  userMail: "",
  dataBoxOpened: {},
  usedBoxOpened: false,
  memberProfile: {},
};

export const LOGIN = "LOGIN";
export const PROFILE = "PROFILE";
export const LOGOUT = "LOGOUT";
export const CHANGE_ROLE = "CHANGE_ROLE";
export const EMAIL_TO_RESET_PASSWORD = "EMAIL_TO_RESET_PASSWORD";
export const UPDATE_ROLES = "UPDATE_ROLES";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_NAME = "UPDATE_NAME";
export const DATA_PROFILE = "DATA_PROFILE";
export const UPDATE_FIRST_LOGIN = "UPDATE_FIRST_LOGIN";
export const AUTH_CLEAN = "AUTH_CLEAN";

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        values: action.payload,
        userRole: action.payload.userRole,
        userName: action.payload.userName,
        token: action.payload.token,
        permissions: action.payload.permissions,
        headquarter: action.payload.headquarter,
        dataBoxOpened: action.payload.box,
        usedBoxOpened: action.payload.usedBox,
        memberId: action.payload.member.id,
        firstLogin: action.payload.firstLogin,
        roles: action.payload.roles,
        member: action.payload.member,
      };
    }
    case PROFILE: {
      return { ...state, user: action.payload.user };
    }
    case LOGOUT: {
      // return { ...state, values: action.payload, userRole: "guest" };
      return initialState;
    }
    case CHANGE_ROLE: {
      return { ...state, userRole: action.userRole };
    }
    case EMAIL_TO_RESET_PASSWORD: {
      return { ...state, userMail: action.payload };
    }
    case UPDATE_ROLES: {
      return {
        ...state,
        roles: action.payload.roles,
        userRole: action.payload.mainRole,
      };
    }
    case UPDATE_EMAIL: {
      return {
        ...state,
        member: action.payload,
      };
    }
    case UPDATE_NAME: {
      return {
        ...state,
        userName: action.payload,
      };
    }
    case DATA_PROFILE: {
      return {
        ...state,
        memberProfile: action.payload,
      };
    }
    case UPDATE_FIRST_LOGIN: {
      return {
        ...state,
        firstLogin: false,
      };
    }
    case AUTH_CLEAN:
      return {
        ...state,
        userRole: "",
        userName: "",
        user: {},
        token: "",
        permissions: [],
        headquarter: {},
        memberId: "",
        firstLogin: false,
        roles: [],
        member: {},
        userMail: "",
        dataBoxOpened: {},
        usedBoxOpened: false,
        memberProfile: {},
      };
    default: {
      return state;
    }
  }
};

export default auth;
