const initialState = {
  listDepartments: [],
  listProvince: [],
  listDistrict: [],
  listHeadquarter: [],
  listGroups: [],
  listGroupsLo: [],
  listGroupsNa: [],
  listGroupsBySede: [],
  listSubjects: [],
  listPrograms: [],
  listProgramsLo: {},
  listProgramsNa: {},
  listPaymentMethods: [],
  listDegrees: [],
  listRateByHQ: [],
  listRateCategory: {},
  listRateBySedeCategory: [],
  listMember: [],
  listBoxes: [],
  listCountries: [],
  listCharges: [],
};

export const DEPARTMENTS_INDEX = "DEPARTMENTS_INDEX";
export const PROVINCE_INDEX = "PROVINCE_INDEX";
export const DISTRICT_INDEX = "DISTRICT_INDEX";
export const PROVINCE_CLEAN = "PROVINCE_CLEAN";
export const DISTRICT_CLEAN = "DISTRICT_CLEAN";
export const HEADQUARTER_INDEX = "HEADQUARTER_INDEX";
export const HEADQUARTER_CLEAN = "HEADQUARTER_CLEAN";
export const GROUP_INDEX = "GROUP_INDEX";
export const GROUP_LOCAL_INDEX = "GROUP_LOCAL_INDEX";
export const GROUP_NATIONAL_INDEX = "GROUP_NATIONAL_INDEX";
export const GROUPS_BY_HQ_INDEX = "GROUPS_BY_HQ_INDEX";
export const GROUP_CLEAN = "GROUP_CLEAN";
export const SUBJECT_INDEX = "SUBJECT_INDEX";
export const SUBJECT_CLEAN = "SUBJECT_CLEAN";
export const MASTER_CLEAN = "MASTER_CLEAN";
export const PROGRAM_LIST_INDEX = "PROGRAM_LIST_INDEX";
export const PROGRAM_LIST_NATIONAL_INDEX = "PROGRAM_LIST_NATIONAL_INDEX";
export const PROGRAM_LIST_LOCAL_INDEX = "PROGRAM_LIST_LOCAL_INDEX";
export const PAYMENT_METHODS_INDEX = "PAYMENT_METHODS_INDEX";
export const PAYMENT_METHODS_CLEAN = "PAYMENT_METHODS_CLEAN";
export const DEGREES_INDEX = "DEGREES_INDEX";
export const RATE_CATEGORY_LIST = "RATE_CATEGORY_LIST";
export const RATE_BY_SEDE_CATEGORY_LIST = "RATE_BY_SEDE_CATEGORY_LIST";
export const RATE_BY_HQ = "RATE_BY_HQ";
export const MEMBER_LIST = "MEMBER_LIST";
export const BOXES_INDEX = "BOXES_INDEX";
export const COUNTRIES_INDEX = "COUNTRIES_INDEX";
export const CHARGES_INDEX = "CHARGES_INDEX";

export const resources = (state = initialState, action) => {
  switch (action.type) {
    case DEPARTMENTS_INDEX:
      return {
        ...state,
        listDepartments: action.payload,
      };
    case PROVINCE_INDEX:
      return {
        ...state,
        listProvince: action.payload,
      };
    case PROVINCE_CLEAN:
      return {
        ...state,
        listProvince: [],
        listDistrict: [],
      };
    case DISTRICT_INDEX:
      return {
        ...state,
        listDistrict: action.payload,
      };
    case DISTRICT_CLEAN:
      return {
        ...state,
        listDistrict: [],
      };
    case HEADQUARTER_INDEX:
      return {
        ...state,
        listHeadquarter: action.payload,
      };
    case HEADQUARTER_CLEAN:
      return {
        ...state,
        listHeadquarter: [],
      };
    case GROUP_INDEX:
      return {
        ...state,
        listGroups: action.payload,
      };
    case GROUP_LOCAL_INDEX:
      return {
        ...state,
        listGroupsLo: action.payload,
      };
    case GROUP_NATIONAL_INDEX:
      return {
        ...state,
        listGroupsNa: action.payload,
      };
    case GROUPS_BY_HQ_INDEX:
      return {
        ...state,
        listGroupsBySede: action.payload,
      };
    case GROUP_CLEAN:
      return {
        ...state,
        listGroups: [],
      };
    case SUBJECT_INDEX:
      return {
        ...state,
        listSubjects: action.payload,
      };
    case SUBJECT_CLEAN:
      return {
        ...state,
        listSubjects: [],
      };
    case PROGRAM_LIST_INDEX:
      return {
        ...state,
        listPrograms: action.payload,
      };
    case PROGRAM_LIST_NATIONAL_INDEX:
      return {
        ...state,
        listProgramsNa: action.payload,
      };
    case PROGRAM_LIST_LOCAL_INDEX:
      return {
        ...state,
        listProgramsLo: action.payload,
      };
    case PAYMENT_METHODS_INDEX:
      return {
        ...state,
        listPaymentMethods: action.payload,
      };
    case PAYMENT_METHODS_CLEAN:
      return {
        ...state,
        listPaymentMethods: [],
      };
    case DEGREES_INDEX:
      return {
        ...state,
        listDegrees: action.payload,
      };
    case RATE_CATEGORY_LIST:
      return {
        ...state,
        listRateCategory: action.payload,
      };
    case RATE_BY_SEDE_CATEGORY_LIST:
      return {
        ...state,
        listRateBySedeCategory: action.payload,
      };
    case RATE_BY_HQ:
      return {
        ...state,
        listRateByHQ: action.payload,
      };
    case MEMBER_LIST:
      return {
        ...state,
        listMember: action.payload,
      };
    case BOXES_INDEX:
      return {
        ...state,
        listBoxes: action.payload,
      };
    case COUNTRIES_INDEX:
      return {
        ...state,
        listCountries: action.payload,
      };
    case CHARGES_INDEX:
      return {
        ...state,
        listCharges: action.payload,
      };
    case MASTER_CLEAN:
      return {
        ...state,
        listDepartments: [],
        listProvince: [],
        listDistrict: [],
        listHeadquarter: [],
        listGroups: [],
        listGroupsLo: [],
        listGroupsNa: [],
        listGroupsBySede: [],
        listSubjects: [],
        listPrograms: [],
        listProgramsLo: {},
        listProgramsNa: {},
        listPaymentMethods: [],
        listDegrees: [],
        listRateByHQ: [],
        listRateCategory: {},
        listRateBySedeCategory: [],
        listMember: [],
        listBoxes: [],
        listCountries: [],
        listCharges: [],
      };
    default:
      return state;
  }
};

export default resources;
