const initialState = {
  listData: {},
  data: {},
};

export const HEADQUARTER_INDEX = "HEADQUARTER_INDEX";
export const HEADQUARTER_INDEXONE = "HEADQUARTER_INDEXONE";
export const CLEAN_HEADQUARTER = "CLEAN_HEADQUARTER";
export const HEADQUARTER_CLEAN = "HEADQUARTER_CLEAN";

export const headquarter = (state = initialState, action) => {
  switch (action.type) {
    case HEADQUARTER_INDEX:
      return {
        ...state,
        listData: action.payload,
      };
    case HEADQUARTER_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case CLEAN_HEADQUARTER: {
      return {
        ...state,
        data: {},
      };
    }
    case HEADQUARTER_CLEAN: {
      return {
        ...state,
        listData: {},
        data: {},
      };
    }
    default:
      return state;
  }
};

export default headquarter;
