const initialState = {
  listData: [],
  listDataPool: [],
  listDataHistorico: [],
  data: {},
  listMembersByDocument: {},
};

export const REQUEST_MANAGEMENT_INDEX = "REQUEST_MANAGEMENT_INDEX";
export const REQUEST_POOL_INDEX = "REQUEST_POOL_INDEX";
export const REQUEST_HISTORY_INDEX = "REQUEST_HISTORY_INDEX";
export const REQUEST_INDEXONE = "REQUEST_INDEXONE";
export const REQUEST_CLEAN = "REQUEST_CLEAN";
export const MEMBERS_BY_DOCUMENT = "MEMBERS_BY_DOCUMENT";

export const requestManagement = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MANAGEMENT_INDEX:
      return {
        ...state,
        listData: action.payload,
      };
    case REQUEST_POOL_INDEX:
      return {
        ...state,
        listDataPool: action.payload,
      };
    case REQUEST_HISTORY_INDEX:
      return {
        ...state,
        listDataHistorico: action.payload,
      };
    case REQUEST_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case REQUEST_CLEAN: {
      return {
        ...state,
        listData: [],
        listDataPool: [],
        listDataHistorico: [],
        data: {},
        listMembersByDocument: {},
      };
    }
    case MEMBERS_BY_DOCUMENT:
      return {
        ...state,
        listMembersByDocument: action.payload,
      };
    default:
      return state;
  }
};

export default requestManagement;
