const initialState = {
  listData: {},
  data: {},
};

export const MATERIA_INDEX = "MATERIA_INDEX";
export const MATERIA_INDEXONE = "MATERIA_INDEXONE";
export const MATERIA_CLEAN = "MATERIA_CLEAN";

export const Materia = (state = initialState, action) => {
  switch (action.type) {
    case MATERIA_INDEX:
      return {
        ...state,
        listData: action.payload,
      };
    case MATERIA_INDEXONE:
      return {
        ...state,
        data: action.payload,
      };
    case MATERIA_CLEAN:
      return {
        ...state,
        listData: {},
        data: {},
      };
    default:
      return state;
  }
};

export default Materia;
