const initialState = {
  tabActiveMA: 1,
  listDataSalonesParticipante: {},
  listDataSalonesEncargado: {},
  listDataDetailEncargadoSalon: {},
  listDataSalonesParticipanteQualification: {},
  listDataSalonesParticipanteAssistan: {},

  //TAB ACTIVITIES
  listProgramsAct: {},
  listGroupsAct: {},
  listSubjectsAct: {},
};

export const SALONES_PARTICIPANTE_INDEX = "SALONES_PARTICIPANTE_INDEX";
export const SALONES_DETAIL_SALON_ENCARGADO_INDEX =
  "SALONES_DETAIL_SALON_ENCARGADO_INDEX";
export const SALONES_ENCARGADO_INDEX = "SALONES_ENCARGADO_INDEX";
export const SALONES_PARTICIPANTE_QUALIFICATION_INDEX =
  "SALONES_PARTICIPANTE_QUALIFICATION_INDEX";
export const SALONES_PARTICIPANTE_ASSISTAN_INDEX =
  "SALONES_PARTICIPANTE_ASSISTAN_INDEX";
export const MY_REQUEST_CLEAN = "MY_REQUEST_CLEAN";
export const PROGRAMS_ACT_INDEX = "PROGRAMS_ACT_INDEX";
export const GROUPS_ACT_INDEX = "GROUPS_ACT_INDEX";
export const SUBJECTS_ACT_INDEX = "SUBJECTS_ACT_INDEX";
export const MA_UPDATE_TAB = "MA_UPDATE_TAB";
export const MA_DEFAULT_TAB = "MA_DEFAULT_TAB";
export const MA_CLEAN = "MA_CLEAN";

export const myActivities = (state = initialState, action) => {
  switch (action.type) {
    case SALONES_PARTICIPANTE_INDEX:
      return {
        ...state,
        listDataSalonesParticipante: action.payload,
      };
    case SALONES_DETAIL_SALON_ENCARGADO_INDEX:
      return {
        ...state,
        listDataDetailEncargadoSalon: action.payload,
      };
    case SALONES_ENCARGADO_INDEX:
      return {
        ...state,
        listDataSalonesEncargado: action.payload,
      };
    case SALONES_PARTICIPANTE_QUALIFICATION_INDEX:
      return {
        ...state,
        listDataSalonesParticipanteQualification: action.payload,
      };
    case SALONES_PARTICIPANTE_ASSISTAN_INDEX:
      return {
        ...state,
        listDataSalonesParticipanteAssistan: action.payload,
      };
    case MY_REQUEST_CLEAN:
      return {
        ...state,
        listData: [],
        data: {},
      };
    case PROGRAMS_ACT_INDEX:
      return {
        ...state,
        listProgramsAct: action.payload,
      };
    case GROUPS_ACT_INDEX:
      return {
        ...state,
        listGroupsAct: action.payload,
      };
    case SUBJECTS_ACT_INDEX:
      return {
        ...state,
        listSubjectsAct: action.payload,
      };
    case MA_UPDATE_TAB:
      return {
        ...state,
        tabActiveMA: action.payload,
      };
    case MA_DEFAULT_TAB:
      return {
        ...state,
        tabActiveMA: 1,
      };
    case MA_CLEAN:
      return {
        ...state,
        tabActiveMA: 1,
        listDataSalonesParticipante: {},
        listDataSalonesEncargado: {},
        listDataDetailEncargadoSalon: {},
        listDataSalonesParticipanteQualification: {},
        listDataSalonesParticipanteAssistan: {},
        listProgramsAct: {},
        listGroupsAct: {},
        listSubjectsAct: {},
      };
    default:
      return state;
  }
};

export default myActivities;
