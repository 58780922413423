import axios from "axios";
import { toast } from "react-toastify";
import { URL_API } from "./redux/backend.enpoints";
import { history } from "./history";

const CancelToken = axios.CancelToken;

const cancelTokenSource = CancelToken.source()

const instanceAxios = axios.create({
  baseURL: URL_API.apiUrl,
  cancelToken: cancelTokenSource.token
});

instanceAxios.defaults.headers.common = {
  Authorization: localStorage.getItem("token"),
};

instanceAxios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instanceAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    /**
     * A network error occurred.
     * This could be a CORS issue or a dropped internet connection.
     * It is not possible for us to know.
     */
    if (typeof error.response === "undefined") {
      error.response = {
        data: {
          message: "Servicio no disponible.",
          statusResponse: {
            code: 503,
            message: "Servicio no disponible.",
          },
          timestamp: null,
          data: null,
        },
        config: null,
        headers: null,
        status: 503,
        statusText: "Service Unavailable",
      };
    }

    let { status, data } = error.response;
    // console.log("########", error.response);
    // console.log("####history", history);
    if (status === 401 && history.location.pathname != "/login") {
      cancelTokenSource.cancel('Operation canceled');
      toast.error("Su sesión ha caducado");
      localStorage.clear();
      // history.go(0);
      // store.dispatch({ type: "LOGOUT" });
      // store.dispatch({ type: "MASTER_CLEAN", payload: {} });
      // store.dispatch({ type: "RESOURCE_UPLOAD", payload: true });
      history.replace("/login");
      window.location.reload();
    }

    if (status === 403 && history.location.pathname != "/login") {
      cancelTokenSource.cancel('Operation canceled');
      toast.error("El rol que tiene actualmente, no cuenta con  los permisos para ingresar a la página señalada");
      history.replace("/datos-miembro");
    }

    if (data instanceof ArrayBuffer) {
      data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(data)));
    }

    let message = data.message;

    // if (status === 422) {
    //   const errors = Object.keys(data.message ? data.message : {});

    //   if (errors.length > 0) {
    //     message = data.message[`${errors[0]}`].reduce((value, item) => {
    //       return `${value} ${item}`;
    //     }, "");
    //   }
    // }
    // if (status === 500 || status === 405) {
    //   toast.error("No se ha podido completar la acción");
    // }
    // if (status === 503 || status === 405) {
    //   toast.error(data.message);
    // }

    // if (message) toast.error(message);

    // if (status === 404) {
    //   toast.error(
    //     message || "Servicio no disponible, comuniquese con su administrador"
    //   );
    //   message = null;
    // }

    if (status === 500) {
      toast.error(
        message ||
          "No se ha podido completar la acción, comuniquese con su administrador"
      );
      message = null;
    }

    // if (message && status !== 401) {
    //   toast.error(message);
    // }
    return Promise.reject(error);
  }
);

export { instanceAxios };
